.time1 {
  font-size: 50px;
  color: #0e6eff;
  margin-left: 20px;
}
.read-tip1 {
  position: absolute;
  border: 2px #eb8b33 solid;
  border-radius: 10px;
  padding: 30px;
  left: 200px;
  top: 240px;
  background-color: #fff;
  line-height: 50px;  
}

.read-tip2 {
  position: absolute;
  border: 2px #eb8b33 solid;
  border-radius: 10px;
  padding: 30px;
  left: 156px;
  top: 240px;
  background-color: #fff;
  line-height: 50px;  
}