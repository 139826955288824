@import '@/assets/css/default.scss';

@mixin bsCont {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  text-align: center;
  line-height: 90px;
  color: $col-8;
  margin: 10px 10px 0 0;
  cursor: pointer;
  float: left;
}

.kb-container {
  width: 400px;
  margin: 0 auto;
}

.kb-col-l {
  width: 300px;
  float: left;

  li{
    @include bsCont;
    background-color: $blueBtn;
  }
  li.disInput{
    @include bsCont;
    background-color: #d5d8da;
  }
  li:last-child {
    width: 190px;
  }
}

.kb-col-r {
  width: 100px;
  float: left;

  li {
    @include bsCont;
  }
  li.disInput{
    background-color: #d5d8da!important;
  }
  li.back-btn {
    background-color: $redBtn;
  }

  li.success-btn {
    background-color: $greedBtn;
    margin-top: 210px;
  }
}