
.rec-r-card {
  width: 860px;
  margin: 0 auto;
  padding-top: 100px;

  .r-card-box {
    float: left;
    width: 320px;
    margin-top: 50px;
    margin-right: 80px
  }
}

.rec-amount-info {
  margin-top: 50px;
  text-align: center;
  span {
    margin-right: 100px;
    font-size: 26px;
  }
}

.rec-amount-row {
  width: 880px;
  margin: 50px auto 0;

  .rec-amount-l {
    width: 400px;
    margin-top: 10px;
    float: left;
  }

  .rec-amount-r {
    width: 480px;
    float: left;
  }
}