$h-full: 100%;

/* font-size */
$f20: 20px;
$f22: 22px;
$f26: 26px;
$f30: 30px;
$f40: 40px;
$f50: 50px;

/* font-color */
$col: #76899d;
$col-1: #e65340;
$col-2: #9e9e9e;
$col-3: #61b0ef;
$col-4: #2ecb62;
$col-5: #f6c435;
$col-6: #f59943;
$col-7: #32b16e;
$col-8: #fff;
$col-9: #000;
$col-10: #dad9d4;
$col-11: #2986f9;

/* button-color */
$blueBtn: #61b0ef;
$redBtn: #f76e6e;
$greedBtn: #00bd58;

/* background-color */
$bg-col-1: #fff;
$bg-col-2: #f7d65e;
$bg-col-3: #65acf2;
$bg-col-4: #4cc051;
$bg-col-5: #e4edf1;
$bg-col-6: #eae7e7;
$bg-col-7: #ccc;
