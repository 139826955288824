@import '@/assets/css/default.scss';

.insurBox {
  list-style: 40px;
}

.pay-dia-list p{
  width: 50%;
  float: left;
  line-height: 2.5!important;
}
.pay-balance {
  text-align: center;
  em {
    margin-right: 30px;
    font-weight: normal;
    font-size: 28px;
  }
}
.medium-List {
  a {
    display: inline-block!important;
    margin: 10px;

    i{
      font-size: 23px;
      font-weight: bold;
      color: #626e7e;
    }
  }
}
.recharge-list {
  .recharge-box {
    display: inline-block!important;
    margin: 10px;

    i{
      font-size: 23px;
      font-weight: bold;
      color: #626e7e;
    }
  }
}

.insurLab {
  text-align: right;
  color: #3a3a3a;
}
.insurLab2 {
  text-align: right;
  color: #2373f0;
}
.insurLab2 {
  text-align: right;
  color: #2373f0;
}

.wid200 {
  width: 80%;
  margin: 0 auto;
}
