@import '../../assets/css/default.scss';

@mixin bsCont {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  line-height: 80px;
  color: $col-8;
  margin: 10px 10px 0 0;
  font-size: 30px;
  cursor: pointer;
}

.lk-container {
  padding: 20px;
  background-color: $col-8;
  .lk-row{
    text-align: center;
    span{
      @include bsCont;
      background-color: $blueBtn;
    }
    .kl-Backspace {
      width: 100px;
      background-color: $redBtn
    }
    .klSuccess {
      width: 100px;
      background-color: $col-7;
    }
  }
}

.kbNumBoard {
  overflow: hidden
}
.kbNumBoard li{
  @include bsCont;
  background-color: $blueBtn;
}
