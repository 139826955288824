@import '@/assets/css/default.scss';

@mixin bsCont {
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 8px;
  text-align: center;
  line-height: 80px;
  color: $col-8;
  margin: 10px 10px 0 0;
  cursor: pointer;
  float: left;
}

.lk-container {
  padding: 20px;
  background-color: $col-8;

  .lk-row{

    span{
      @include bsCont;
      background-color: $blueBtn;

    }
    .kl-Backspace {
      width: 100px;
      background-color: $redBtn
    }

  }


}