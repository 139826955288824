@import '@/assets/css/default.scss';

.card-box {
  width: 95%;
  height: 750px;
  padding: 60px 50px;
  margin: 15px auto 30px;
  background-color: $bg-col-1;
  h1 {
    font-size: $f50;
    text-align: center;
    padding-bottom: 40px;
  }
  .card-info {
    width: 700px;
    float: left;
    line-height: 45px;
  }
  .card-btn-box {
    float: left;
  }
  .card-btn {
    padding: 50px 0 0 50px;
  }

  .jzk-box {
    margin-top: 50px;
    text-align: center;
  }
  
  .vis-card-box {
    width: 600px;
    margin: 20px auto 40px;
  }
  
  .vis-img {
    float: left;
    width: 330px;
    height: 330px;
    margin-left: 190px;
  }
  
  .vis-img img {
    width: 100%;
    height: 100%;
  }  
}

.vis-ins {
  float: left;
  margin-left: 50px;
}


