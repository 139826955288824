@import '@/assets/css/default.scss';

.dep-list {
  height: 650px;
  margin-top: 20px;
  overflow-y: auto;
}

.dep-cont {
  font-size: 26px;

  .dep-cont-title {
    color: #000;
    padding: 40px 0 20px;

    .ma-icon {
      font-size: 30px;
      margin-right: 10px;
    }
  }
  .dep-cont-list {
    margin-top: 30px;
  }
  .dep-cont-list p{ 
    width: 22%;
    float: left;
    height: 80px;
    line-height: 80px;
    margin: 30px 15px 0;
    border: 1px $col-3 solid;
    text-align: center;
    border-radius: 5px;
    color: $col-3;
    cursor: pointer;
  }
}

.sd-const {
  width: 22%;
  margin-bottom: 50px;
  font-size: 24px;
  border: 1px $col-3 solid;
  border-radius: 8px;
  padding: 15px;
  margin: 0 15px 40px;
  float: left;

  .sd-cloum {
    .sd-til {
      padding-top: 10px;
      font-size: 18px;
      font-weight: normal;
    }
    .sd-speciality {
      font-size: 22px;
    }
    .sd-service-fee{
      padding: 10px 0;
    }
    .sd-amount {
      color: $col-1;
    }
    .sd-surplus {
      color: $col-5
    }

    p {
      font-size: 18px;
      padding-top:10px;
    }
  }
}

.none-date{
  margin-top: 200px;
  font-size: 40px; 
  text-align: center;
}
.vis-time {
  padding: 20px 0;
}
.noSchedule {
  text-align: center;
  margin: 50px 0;
  font-size: 40px;
}