@import '@/assets/css/default.scss';

.header{
  display: flex;
  padding-top: 15px;
  height: 116px;
  background: url('../../assets/img/head.jpg') no-repeat;
  background-size: cover;
  position: relative;
}

.h-logo {
  flex: 1;
  padding:10px 0 0 30px;
  font-size: $f40;
  color: #145d98;
}

.h-timer {
  width: 170px;
  color: #e65340;
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -46px 0 0 -38px;

  span {
    border: 3px $col-1 solid;
    display: block;
    width: 80px;
    height: 80px;
    line-height: 75px;
    border-radius: 40px;
    text-align: center;
  }
}

.h-date {
  width: 430px;
  margin-right: 30px;
  color: #000;
  text-align: right;

  .h-text {
    font-size: $f22;
    padding-top: 6px;
  }

  .mt-20 {
    font-size: 19px!important;
    color: red;

    .quit-time {
      font-size: $f50;
      margin: 0 5px;
    }
  }
}