@import '@/assets/css/default.scss';

.read-card{
  margin-top: 60px;
  text-align: center;
}
.con-card {
  margin-top: 60px;
  width: 470px;
  margin: 60px auto 0;

  span, em {
    display: block;
  }
  .idcard-con {
    width: 470px;
    height: 295px;
    position: relative;
    color: #000;
    font-size: 20px;

    .pos-a {
      position: absolute;
     
    }
    .idcard-name {
      left: 80px;
      top: 47px;
      z-index: 20;
    }
    .idcard-sex {
      left: 80px;
      top: 82px;
      z-index: 20;
    }
    .idCard-birthday{
      left: 80px;
      top: 115px;
      z-index: 20;
    }
    .idcard-peopleNation {
      left: 180px;
      top: 82px;
      z-index: 20;
    }
    .idcard-address {
      left: 80px;
      top: 150px;
      z-index: 20;
    }
    .idcard-card {
      left: 158px;
      top: 242px;
      z-index: 20;
    }
    
    .idCard-img{
      position: absolute;
      width: 470px;
      height: 295px;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  em {
    margin-top: 20px;
    text-align: right;
  }
}

.ins-phone {
  width: 470px;
  margin: 40px auto 10px;
}

.suc-card {
  width: 400px;
  height: 268px;
  margin: 70px auto 0;
  background: url("../../assets/img/wait.png") no-repeat;

  span {
    display: block;
    padding: 64px 0 0 28px;
  }
}

/* 就诊卡列表 */
.rec-list{

  span {
    width: 350px;
    display: inline-block;
    text-align: center;
    margin: 0 60px;
    var{
      width: 350px;
      height: 200px;
      display: block;
      img {
        width: 100%;
        height: 100%;
        text-align: center;
        border-radius: 8px;
      }
    }
    i {
      text-align: center;
      display: block;
      padding: 10px 0;
      font-size: 24px;
    }
  }
}

.create-bankinfo {
  width: 470px;
  height: 271px;
  margin: 0 auto;
  background: url("../../assets/img/bankCard.png") no-repeat;
  background-size: cover;
  span {
    font-size: 24px;
    color: #000;
    line-height: 42px;
    padding-left: 115px;
  }
  span:nth-child(1){
    padding-top: 24px;
  }
}