@import '@/assets/css/default.scss';

@media screen and (min-width:600px) and (max-width:1080px){
  .main-content, 
  .mf-content {
    height: 1705px;
  }
  .main-row{
    width: 980px;
  }
}

@media screen and (min-width:1081px){
  .main-content, 
  .mf-content {
    height: 810px;
  }
  .main-row{
    width: 1280px;
  }
}


.main-row {
  margin: 0 auto;
  padding: 40px 0;
  overflow: hidden;
}

.main-col {
  width: 240px;
  float: left;
  background-color: $bg-col-1;
  border-radius: 5px;
  margin: 40px 40px 0;
  height: 300px;
  padding: 10px;
  text-align: center;
  -moz-box-shadow:5px 5px 7px rgba(0, 0, 0, .2); 
  -webkit-box-shadow:5px 5px 7px rgba(0, 0, 0, .2); 
  box-shadow:5px 5px 7px rgba(0, 0, 0, .2);

  p {
    width: 100%;
    height: 100%;
    display: block;
    i {
      font-size: 140px!important;
      padding-top: 20px;
      display: block;
    }
  }
  span {
    display: block;
    padding-top: 20px;
  }
  em {
    font-size: 20px;
    color: $col-10;
  }
}

.m-cont {
  display: block;
  width: 112px;
  height: 112px;
  margin: 40px auto 0;
}

.m-gh {
  background: url('../../assets/img/main-gh.png') no-repeat;
}
.m-jf {
  background: url('../../assets/img/main-jf.png') no-repeat;
}
.m-pf {
  background: url('../../assets/img/main-fp.png') no-repeat;
}
.m-dy {
  background: url('../../assets/img/main-dy.png') no-repeat;
}

.main-more {
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0px;
  display: none;
  z-index: 900;
  background-color: $bg-col-1;
}

.mf-content {
  overflow-y: auto;

  .mf-row {
    padding: 20px 50px;

    .mf-til {
      font-size: 24px;
      color: $col-9;
    }

    li {
      float: left;
      width: 180px; 
      height: 200px;
      background-color: $bg-col-1;
      margin: 50px 64px 0;
      border-radius: 5px;
      i {
        display: block;
        font-size: 100px;
        text-align: center;
        margin-top: 14px;
      }
      span {
        display: block;
        text-align: center;
        margin-top:19px;
        color: $col-9;
        font-size: 20px;
      }
    }
  }
}
