@import '@/assets/css/default.scss';

.bg-red {
  background-color: $redBtn
}
.greedBtn {
  background-color: $greedBtn;
  border: 1px $greedBtn solid;
}

html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu { margin:0;padding:0; }
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details { display:block; }
table { border-collapse:collapse;border-spacing:0; }
caption,th { text-align:left;font-weight:normal; }
html,body,fieldset,img,iframe,abbr { border:0; }
i,cite,em,var,address,dfn { font-style:normal; }
[hidefocus],summary { outline:0; }
li { list-style:none; }
h1,h2,h3,h4,h5,h6,small { font-size:100%; color: $col;}
sup,sub { font-size:83%; }
pre,code,kbd,samp { font-family:inherit; }
q:before,q:after { content:none; }
textarea { overflow:auto;resize:none; }
label,summary { cursor:default; }
a,button { cursor:pointer; }
h1,h2,h3,h4,h5,h6,em,strong,b { font-weight:bold; }
del,ins,u,s,a,a:hover { text-decoration:none; }
body,textarea,input,button,select,keygen,legend { font:12px/1.14 arial,\5b8b\4f53;color:#333;outline:0; }
a,a:hover { color:#333; }

body {
  font-family: Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif!important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $col;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: $f30
}

html, body {
  width: $h-full;
  height: $h-full;
}


@media screen and (min-width:600px) and (max-width:1080px){
  .main-container {
    height: 1920px;
    background: #e2eff5 url('../img/bg1920.jpg') no-repeat;
  }
  .context-container {
    height: 1685px;
  }
}

@media screen and (min-width:1081px){
  .main-container {
    height: 1024px;
    background: #e2eff5 url('../img/bg1024.jpg') no-repeat;
  }
  .context-container {
    height: 790px;
  }
}

.main-container {
  width: 100%;
  background-size: cover;
  background-color: #e2eff5;
  margin: 0 auto
}

.context-container {
  width: 95%;
  
  padding: 40px;
  margin: 10px auto;
  background-color: rgba(255,255,255,1);
}
.crumbs {
  text-align: center;
  padding: 20px 0 30px;
  font-weight: normal;
}
.business-name {
  text-align: center;
  font-weight: normal;
  color: $col-9;
  font-size: 34px;
}

.flx {
  display: flex;
}
.col {
  flex: 1;
}

// common style
.left{
  float:left;
}
.right{
  float:right;
}
.clearfix{
  zoom:1;
}
.clearfix:after{
  content:'';
  visibility:hidden;
  display:block;
  clear:both;
  height:0;
  font-size:0;
}

.f-col-1 {
  color: $col-1;
}
.f-col-2 {
  color: $col-2;
}
.f-col-3 {
  color: $col-3;
}
.f-col-4 {
  color: $col-4;
}
.f-col-5 {
  color: $col-5;
}
.f-col-6 {
  color: $col-6;
}
.f-col-7 {
  color: $col-7;
}
.f-col-9 {
  color: $col-9;
}
.f-col-11 {
  color: $col-11;
}

.text-r {
  text-align: right;
}
.text-l {
  text-align: left;
}
.text-c {
  text-align: center;
}

.small {
  font-size: 80%;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}

/* 边距 */
.mar10 { 
  margin: 10px;
}
.mar-r-20{
  margin-right: 20px;
}
.mar-r-30{
  margin-right: 30px;
}
.mar-r-40{
  margin-right: 40px;
}
.mar-r-50{
  margin-right: 50px;
}
.mar-r-60{
  margin-right: 60px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-50 {
  margin-top: 25px;
}
.m-lr-20 {
  margin: 0 20px;
}
.pl-24 {
  padding-left: 24px
}
.pl-44 {
  padding-left: 44px;
}
.pl-77 {
  padding-left: 77px;
}
.pt-20{
  padding-top: 20px;
}
.pb-50 {
  padding-bottom: 50px;
}

/* 文字颜色 */
.col-warning {
  color: #e53333;
}

.size20 {
  font-size: $f20;
}
.size30 {
   font-size: $f30;
}
.size40 {
  font-size: $f40;
}
.size50 {
  font-size: $f50;
}

.ant-spin {
  font-size: 30px!important;
}
.ant-spin-dot{
  width: 50px;
  height: 50px;
}
.ant-spin-dot i{
  width: 20px;
  height: 20px;
}
.ant-spin .ant-spin-text {
  font-size: 40px!important;
  padding-top: 50px!important;
  font-weight: bold;
}

.footer {
  padding: 20px 30px 0;
  height: 94px;
  background-color: rgba(193,213,228, .8);
  position: relative;
}
.footer .address {
  position: absolute;
  width: 600px; 
  height: 64px;
  top: 18px;
  left: 50%;
  margin-left: -300px;
  background: url('../img/address.png') no-repeat;
}

/* icon */
.main-icon {
  font-size: 140px!important;
  padding-top: 20px;
  display: block;
}


/* 模拟输入器 */
.inp-ins-box {
  width: 400px;
}

.inp-ins-box span {
  width: 100px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  background-color: #1890ff;
  color: $col-8;
  margin: 0 10px 20px;
  cursor: pointer;
}

/* 读患者信模块 */
.r-card-info {
  float: left;
  
  p{
    padding: 10px 0;
    font-size: 30px;
  }
}

/* 选择日期 */
.select-data-cont {
  margin-top: 20px;
  position: relative;
  height: 570px;
}

.par-info {
  width: 580px;
  margin: 60px auto;
  padding: 30px 40px;
  background-color: #f1f8ff;

  p {
    padding: 14px 0;

    em, i {
      display: inline-block;
    }
    i {
      float: right;
      color: $col-3
    }
  }
}

.c-content {
  text-align: center;
  margin-top: 50px;
  
  a, i {
    display: block;
  }
  i {
    margin-top: 20px;
  }
}

.c-content-scroll {
  height: 630px;
  padding: 10px;
  overflow-y: scroll;
}

.ant-input-prefix {
  font-size: 21px;
  
}
.ant-input-suffix {
  font-size: 21px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px!important;
}