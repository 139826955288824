.ts-out-box {
  width: 750px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
}
.ts-box {
  float: left;
  width: 200px;
  height: 270px;
  border-radius: 10px;
  background-color: #e1eff5;
  margin: 0 80px;
}
.ts-box i {
  font-size: 150px;
}

.printOut {
  padding-bottom: 20px;
}