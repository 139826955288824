@import '@/assets/css/default.scss';

.print-ico {
  width: 180px; 
  height:180px;
  border-radius: 10px;
  text-align: center;
  line-height: 180px;
  float: left;
  
  i{
    color: $col-8;
    font-size: 160px;
  }
}

.print-ico {
  background-color: $col-3;
}

.print-text {
  width: 490px;
  float: left;
  margin-left: 30px;
  
  h1 {
    color: $col-3;
    font-size: 40px;
    margin: 60px 0 20px;
  }
}

.payment-btn {
  margin-top: 50px;
  text-align: center;
}


.nuc-idcard {
  width: 200px;
  height: 200px;
  background: url('../../assets/img/idcard-ico.png') no-repeat;
  margin: 50px auto;
}
.nuc-row {
  width: 40%;
  margin: 40px auto!important;
}
.nuc-card {
  height: 100px;
  border-radius: 20px;
  background-color: #4ec3ff;
  line-height: 100px;
  text-align: center;
  color: #fff;
}

