@import '@/assets/css/default.scss';

.status-box {
  width: 700px;
  padding-top: 160px;
  margin: 0 auto;
  overflow: hidden;
}

.success-ico, .error-ico {
  width: 180px; 
  height:180px;
  border-radius: 10px;
  
  color: $col-8;
  font-size: 120px;
  text-align: center;
  line-height: 180px;
  float: left;
}
.success-ico {
  background-color: $greedBtn;
}
.error-ico {
  background-color: $redBtn;
}

.success-text, .error-text {
  width: 490px;
  float: left;
  margin-left: 30px;
  
  h1 {
    font-size: 40px;
    margin: 30px 0 20px;
  }
}

.success-text {
  color: $greedBtn;
  h1 {
    color: $greedBtn;
  }
}

.error-text {
  h1 {
    color: $redBtn;
  }
  p{
    color: '#000!important';
    font-size: 30px;
  }
}