.login-cont{
  width: 100%;
  height: 100%;
  // background: -webkit-linear-gradient(top, #63acfb 60%, #92cff7 100%);
  background: url('../../assets/img/admin.jpg') no-repeat;
  background-size: cover;
  overflow: hidden;
}

.login-box{
  margin-top: 110px;
  clear: both;
  overflow: hidden;
  padding-bottom: 260px;

  h1 {
    text-align: center;
    line-height: 70px;
    font-size: 40px;
    color: #fff;
  }
}

.login-context{
  width: 840px;
  margin: 20px auto 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px 0 30px 40px;
  overflow: hidden;
  clear: both;
  border-radius: 10px;

  .l-l {
    width: 300px;
    float: left;
    margin: 20px 0 0 0;
  }
  .l-r {
    width: 500px;
    float: left;
  }
}

.adm-head {
  height: 60px;
  background-color: #2b2b2b;
  h1 {
    float: left;
    line-height: 60px;
    color: #fff;
    padding-left: 20px;
    font-size: 24px;
  }

  .adm-out {
    float: right;
    line-height: 60px;
    padding: 0 40px;
    font-size: 18px;
    color: #fff;
  }
}

.adm-content {
  margin: 20px;
  height: 920px;
  background-color: #fff;
  padding: 0 20px 20px;
  
  h1 {
    font-size: 40px;
    text-align: center;
    color: #000;
    padding: 60px 0 10px;
  }

  .print-tab-btn {
    padding: 30px 0 10px;
    text-align: center;

    span {
      width: 240px; 
      height: 70px;
      line-height: 70px;
      border-radius: 10px;
      border: 1px #ccc solid;
      text-align: center;
      font-size: 26px;
      display: inline-block;
      background-color: #1890ff;
      color: #fff;
    }
    span:first-child {
      margin-right: 100px;
    }

    .payment-slip {

    }
  }
}