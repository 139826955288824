@import '@/assets/css/default.scss';

.pay-list {
  width: 100%;
  height: 650px;
  display: flex;
  margin: 120px auto 0;

  .yhk-bg {
    background-color: $bg-col-2;
  }
  .zfb-bg {
    background-color: $bg-col-3;
  }
  .wx-bg {
    background-color: $bg-col-4;
  }
  .ylq-bg {
    background-color: #13c2c2;
  }
  .nsylq-bg {
    background-color: #1890ff;
  }
  .yb-bg {
    background-color: #F56C6C;
  }
  .eyb-bg {
    background-color: #b37feb;
  }
}
.pay-flx {
  flex: 1;
  height: 300px;
  background-color: $bg-col-5;
  text-align: center;
  margin: 0 20px;
  border-radius: 8px;
  color: $col-8;
  box-shadow: 8px 8px 4px $bg-col-6;
  cursor: pointer;
}

.pay-flx p {
  width: 140px;
  height: 120px;
  background-color: $bg-col-1;
  margin: 55px auto;
  padding: 11px 20px;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
  }
}

.pay-qrcode {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
}

.pay-dia {
  span {
    display: block;
    font-size: 30px;
    color: $col-1;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  i {
    display: block;
    font-size: 30px;
  }
  em {
    font-size: 20px;
    color: #9e9e9e;
  }
}

.pay-info {
  text-align: center;

  h2, p {
    padding: 20px 0;
  }

  .qr-cont {
    width: 800px;
    margin: 20px auto;
  }

  .qr-code {
    width: 280px;
    height: 280px;
    padding: 20px;
    float: left;
    background-color: $bg-col-1;
  }

  .qr-explain {
    width: 400px;
    height: 300px;
    padding: 20px;
    margin-left: 100px;
    background-color: $bg-col-1;
    text-align: left;
    float: left;

    p {
      padding: 10px 0;
    }
  }
}