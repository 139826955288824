.health-qr {
  width: 280px;
	height: 280px;
	margin: 40px auto;
}
.health-til{
	margin-top: 40px;
	text-align: center;
	font-size: 20px;
	color: red;
}