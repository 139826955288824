
.department-list {
  li {
    border: 1px #ccc solid;
    padding: 15px;
    margin-top: 20px;

    .d-name {
      width: 200px;
      height: 200px;
      border-radius: 100px;
      background-color: #49a9ee;
      float: left;
      line-height: 200px;
      text-align: center;
      color: #fff;
      font-size: 34px;
      margin-right: 20px;
    }

    .d-title{
      border-bottom: 1px #ccc dashed;
      padding: 15px 0;
      margin-bottom: 20px;
    }

    .d-context {
      float: left;
      width: 80%;
    }
  }
}

.d-mini {
  font-size: 22px;
  line-height: 30px;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.department-detail {
  p {
    font-size: 28px;
    line-height: 60px;
    text-indent: 2em;
  }
}