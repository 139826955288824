@import '@/assets/css/default.scss';

.not-fonnd-page {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.found-page-img {
  width: 750px;
  height: 519px;
  background: url('../../assets//img//notFound.png') no-repeat;
  margin: 100px auto;
}